.root {
    @apply fixed w-screen top-0 bg-primary z-40 transition-all duration-150;
  }
  
  .link {
    @apply inline-flex items-center text-primary leading-6 font-medium transition ease-in-out duration-75 cursor-pointer text-accents-6 rounded-md p-1;
  }
  
  .link:hover {
    @apply text-accents-9;
  }
  
  .link:focus {
    @apply outline-none text-accents-8 ring-2 ring-pink ring-opacity-50;
  }
  
  .logo {
    @apply cursor-pointer rounded-full transform duration-100 ease-in-out;
  }